import React, { useContext, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { BTAlert } from '@btas/jasper';
import { attachBehavior } from './Spreadsheet/_spreadsheets';
import { addSpreadShortcut } from './Spreadsheet/_spreadsheets/commands/shortcuts/entry';

import EditorContext from './EditorContext';
import SpreadView from './Spreadsheet/SpreadView';
import SideBar from './Spreadsheet/SideBar';
import WorkpaperCopySheetModal from './Spreadsheet/WorkpaperCopySheetModal';
import WorkpaperSendToTPModal from './Spreadsheet/WorkpaperSendToTPModal';
import WorkpaperSendToTPSuccessModal from './Spreadsheet/WorkpaperSendToTPSuccessModal';
import ReadOnlyVersionBar from './Spreadsheet/ReadOnlyVersionBar';
import WorkpaperLockedBar from './Spreadsheet/WorkpaperLockedBar';
import TaxLawSummary from './Spreadsheet/TaxLawSummary';
import GC from '../../../SpreadSheets';
import { CELL_REVIEW } from '../../_shared/DataReference/ReferenceType';
import { UNMARK_CELL_REVIEWED_COMMAND } from './Spreadsheet/_spreadsheets/commands/cellReview/constants';
import './styles.scss';
import { getUser, getUserInfo } from '../../_shared/auth';
import GeneralErrorMessage from '../../_shared/GeneralErrorMessage';
import useHistoryTracker from './HistoryTracker/useHistoryTracker';
import { getJobsStatus } from '../../_shared/jobs/apis';
import CustomLogger from '../../_shared/Logger/CustomLogger.js';
import UserPermissionsContext from '../../_shared/UserPermissionsContext';
import { decodeWorksheetUrl } from './worksheetUrlFormatter';
import SftpModal from '../../_shared/SFTPModal/SftpModal.jsx';
import { EventTrackingContext } from '../../_shared/EventTrackingContext.jsx';
import { eventNames } from '../../_shared/EventTrackingContext/constants.js';
import { setPropValues } from '../../_shared/EventTrackingContext/utils.js';
import { processWorkbookForExport } from './Spreadsheet/shared/utils.js';
import { copyFiles } from './Spreadsheet/WorkpaperSendToTPModal/apis.js';
import { IntegrationType, ResourceType } from './Spreadsheet/WorkpaperSendToTPModal/constants.js';
import WorkpaperSyncCommandsModal from './Spreadsheet/WorkpaperSyncCommandsModal.jsx';

export default function Spreadsheet({
  handleFileMenuAction,
  tooltipManager,
  userLockFullName,
  versionId,
  workpaperId,
  onNeedsReload,
  cellReviewFunctionManager,
}) {
  const {
    spreadRef,
    setCommandMap,
    setCommandsVisibleContext,
    setHasPendingChanges,
    isLocked,
    isLoading,
    commandFailed,
    maxFails,
    successAlertMessage,
    setSuccessAlertMessage,
    workpaperStatus,
    dataReferenceHistoryTracker,
    isGlobalTemplate,
    taxPeriod,
    workbookName,
    showWorkpaperSyncCommandsModal,
    dataLinkStatus,
  } = useContext(EditorContext);

  const { trackEvent } = useContext(EventTrackingContext);

  const { userPermissions } = useContext(UserPermissionsContext);
  const [showWorkpaperCopySheetModal, setShowWorkpaperCopySheetModal] = useState(false);
  const [showWorkpaperSendToTPModal, setShowWorkpaperSendToTPModal] = useState(false);
  const [showWorkpaperSendToTSuccessModal, setShowWorkpaperSendToTSuccessModal] = useState(false);
  const [showWorkpaperSendToSFTPModal, setshowWorkpaperSendToSFTPModal] = useState(false);
  const [showWorkpaperSendToSFTPSuccessModal, setShowWorkpaperSendToSFTPSuccessModal] = useState(false);
  const [sftpFolderPath, setSftpFolderPath] = useState('');
  const [generalError, setGeneralError] = useState({
    message: undefined,
  });

  const [showProgressAlert, setShowProgressAlert] = useState(false);
  const [showCopyAlert, setShowCopyAlert] = useState();
  const { addToHistory, historyTracker, undoAction } = useHistoryTracker(cellReviewFunctionManager, workpaperId);
  dataReferenceHistoryTracker.current = { addToHistory, historyTracker, undoAction };

  const sourceWorkpaperInfo = {
    workbookName,
    taxPeriod,
  };

  const onOpenSftpModal = async path => {
    setGeneralError(null);
    const userInfo = await getUserInfo();
    const email = userInfo?.designatedUser?.email || userInfo?.sharedUser?.email;
    const { companyId } = getUser();
    path = path || '';
    try {
      const { location, fileName, fileSize } = await processWorkbookForExport(
        workpaperId,
        IntegrationType.SFTP,
        workbookName,
        taxPeriod
      );
      const currentDate = new Date().toISOString();
      const destinationPath = `${companyId}${path}/${fileName}`;
      const copyPayload = {
        sourceBucketType: 'Integration',
        sourcePath: location,
        destinationBucketType: IntegrationType.SFTP,
        destinationPath,
        componentType: ResourceType.Spreadsheet,
        resourceId: workpaperId,
        metadata: {
          'user-agent-id': email,
          fileSize: String(fileSize),
          fileExtension: 'xlsx',
          fileName,
          uploadedDate: currentDate,
        },
      };

      await copyFiles(copyPayload);

      CustomLogger.pushLog(CustomLogger.operations.SFTP_INTEGRATION, {
        resourceId: JSON.stringify(workpaperId),
        taxPeriod: taxPeriod ? JSON.stringify(taxPeriod) : '',
        componentType: JSON.stringify(ResourceType.Spreadsheet),
        message: `Workbook on resource id: ${workpaperId} was sent succesfully to SFTP in key: ${destinationPath}`,
      });
      setSftpFolderPath(path);
      handleWorkpaperSendToSFTPModalClose();
      handleWorkpaperSendToSFTPSuccessModalOpen();
    } catch (err) {
      setGeneralError({ message: err });
    }
  };

  const getCustomContextMenuOptions = menuData => {
    // Clean up
    menuData = menuData.filter(({ command, name, text }) => command && name && text);

    menuData = handleUnmarkReviewMenuOption(menuData);
    // Other conditional menu options

    return menuData;
  };

  const handleUnmarkReviewMenuOption = menuData => {
    const sheet = spreadRef.current.getActiveSheet();
    const selections = sheet.getSelections();
    const user = getUser();
    const { dataReferences } = cellReviewFunctionManager.current;

    // Flatten all selected cells into a single array of cell positions
    const selectedCells = selections.flatMap(selected => {
      const rows = Array.from({ length: selected.rowCount }, (_, rowX) => selected.row + rowX);
      const columns = Array.from({ length: selected.colCount }, (_, colY) => selected.col + colY);
      return rows.flatMap(row => columns.map(column => ({ row, column })));
    });

    const userCellReviews = dataReferences.current
      .filter(
        dRef =>
          dRef.type === CELL_REVIEW &&
          selectedCells.some(
            cell => dRef.row === cell.row && dRef.column === cell.column && dRef.sheetName === sheet.name()
          )
      )
      .filter(ref => {
        const parameters = JSON.parse(ref.parameters);
        return parameters.ReviewerUserId === user.userId;
      });

    if (userCellReviews.length > 0) {
      menuData.push({
        text: 'Unmark reviewed',
        name: UNMARK_CELL_REVIEWED_COMMAND,
        command: UNMARK_CELL_REVIEWED_COMMAND,
      });
    } else {
      menuData = menuData.filter(({ command }) => command !== UNMARK_CELL_REVIEWED_COMMAND);
    }

    return menuData;
  };

  const setupCellReviewTracking = () => {
    const markReviewedElement = Array.from(document.querySelectorAll('span.gc-ui-contextmenu-text')).find(element =>
      element.textContent.includes('Mark reviewed')
    );

    const unmarkReviewedElement = Array.from(document.querySelectorAll('span.gc-ui-contextmenu-text')).find(element =>
      element.textContent.includes('Unmark reviewed')
    );

    const handleMarkReviewedClick = event => {
      event.target.setAttribute('data-track-tag', 'Mark Reviewed');
      trackEvent(eventNames.interactiveClick, setPropValues(event, eventNames.interactiveClick, null));
    };

    const handleUnmarkReviewedClick = event => {
      event.target.setAttribute('data-track-tag', 'Unmark Reviewed');
      trackEvent(eventNames.interactiveClick, setPropValues(event, eventNames.interactiveClick, null));
    };

    if (markReviewedElement) {
      markReviewedElement.addEventListener('click', handleMarkReviewedClick);
    }

    if (unmarkReviewedElement) {
      unmarkReviewedElement.addEventListener('click', handleUnmarkReviewedClick);
    }

    return { handleMarkReviewedClick, handleUnmarkReviewedClick, markReviewedElement, unmarkReviewedElement };
  };

  useEffect(() => {
    const spread = spreadRef.current;
    spread.bind(GC.Spread.Sheets.Events.CellClick, function (e, a) {
      decodeWorksheetUrl(a);
    });

    const baseContextMenu = spread.contextMenu.onOpenMenu;

    spread.contextMenu.onOpenMenu = function (menuData, itemsDataForShown, hitInfo, spreadInstance) {
      const customMenuData = getCustomContextMenuOptions(menuData);
      baseContextMenu.call(this, customMenuData, itemsDataForShown, hitInfo, spreadInstance);
      setTimeout(() => setupCellReviewTracking(), 500);
    };

    return () => {
      tooltipManager.clean();
    };
    // Removing lint checks for dependencies because there is no need to re-execute
    // when spreadRef changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (generalError?.message) {
      CustomLogger.pushLog(CustomLogger.operations.SFTP_INTEGRATION, {
        error: JSON.stringify(generalError?.message),
        resourceId: JSON.stringify(workpaperId),
        versionId: JSON.stringify(versionId),
        message: `catch error when trying to export from ${ResourceType.Spreadsheet} to ${IntegrationType.SFTP} ${workpaperId}, version id ${versionId}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalError]);

  const onReadOnlyMode = Boolean(versionId);
  const [readOnlyModeDisplayName, setReadOnlyModeDisplayName] = useState('');

  const readerMode = !userPermissions.includes('edit_workpaper');

  const handleWorkbookInit = ({ Spread: spread, _config }) => {
    spreadRef.current = spread;
    addSpreadShortcut(spread, { undoAction });
    setCommandMap(_config.commandMap);

    attachBehavior({
      spread,
      workpaperId,
      tooltipManager,
      setCommandsVisibleContext,
    });
  };

  const handleWorkpaperCopySheetModalOpen = () => setShowWorkpaperCopySheetModal(true);
  const handleWorkpaperCopySheetModalClose = () => setShowWorkpaperCopySheetModal(false);
  const handleSuccessAlertClose = () => setSuccessAlertMessage(null);
  const handleWorkpaperSendToTPModalOpen = () => setShowWorkpaperSendToTPModal(true);
  const handleWorkpaperSendToTPModalClose = () => setShowWorkpaperSendToTPModal(false);
  const handleWorkpaperSendToTPSuccessModalOpen = () => setShowWorkpaperSendToTSuccessModal(true);
  const handleWorkpaperSendToTPSuccessModalClose = () => setShowWorkpaperSendToTSuccessModal(false);
  const handleWorkpaperSendToSFTPModalOpen = () => {
    setshowWorkpaperSendToSFTPModal(true);
    handleWorkpaperSendToSFTPSuccessModalClose();
  };
  const handleWorkpaperSendToSFTPModalClose = () => {
    setshowWorkpaperSendToSFTPModal(false);
    setGeneralError(null);
  };
  const handleWorkpaperSendToSFTPSuccessModalOpen = () => setShowWorkpaperSendToSFTPSuccessModal(true);
  const handleWorkpaperSendToSFTPSuccessModalClose = () => setShowWorkpaperSendToSFTPSuccessModal(false);

  const removeAlerts = () => {
    handleWorkpaperCopySheetModalClose();
    handleSuccessAlertClose();
    setShowCopyAlert(null);
  };

  const retryFetchJobStatus = async jobIds => {
    let status = 1;
    let retries = 300;

    while (status === 1 && --retries >= 0) {
      await new Promise(r => setTimeout(r, 1000));
      const res = await getJobsStatus({ jobIds: [jobIds] });
      if (res) {
        ({ status } = res[0]);
      }

      console.log('Try#', retries, 'status', status);
      if (retries === 0) {
        throw new Error('Failed to copy sheets, maximum retries limit reached');
      }

      if (status === 3) {
        throw new Error('Copy Sheet job failed');
      }
    }
  };

  const handleCopySheetSjs = async (jobId, operationStartTime, modalPayload, logPayload) => {
    removeAlerts();
    setShowProgressAlert(true);
    try {
      await retryFetchJobStatus(jobId);
      setShowProgressAlert(false);
      setSuccessAlertMessage(modalPayload);
      CustomLogger &&
        CustomLogger.pushLog(CustomLogger.operations.COPY.SHEET, {
          duration: (Date.now() - operationStartTime).toString(),
          ...logPayload,
        });
    } catch (error) {
      setShowProgressAlert(false);
      setShowCopyAlert({ message: error.message });
      CustomLogger &&
        CustomLogger.pushLog(CustomLogger.operations.COPY.SHEET, {
          error: JSON.stringify(error),
          duration: (Date.now() - operationStartTime).toString(),
          ...logPayload,
        });
    }
  };

  const AlertTypes = {
    COPY_SHEET: RenderCopySheetAlert,
    // Add other complex alert types and their rendering functions here
  };

  function RenderCopySheetAlert({ alertData }) {
    const handleClick = () => {
      const newTabUrl = window.location.origin + `/editor/${alertData.workpaperId}`;

      setSuccessAlertMessage(null);
      window.open(newTabUrl, '_blank');
    };

    return (
      <>
        The selected worksheets have been successfully copied to workpaper{' '}
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <a style={{ cursor: 'pointer' }} onClick={handleClick}>
          {alertData.workpaperName}
        </a>{' '}
        {alertData.workpaperTaxPeriod && `for tax period ${alertData.workpaperTaxPeriod}`}
      </>
    );
  }

  function RenderAlert({ alertType, alertData }) {
    if (typeof alertData === 'string') {
      return alertData;
    }
    const Renderer = AlertTypes[alertType];
    return <Renderer alertData={alertData} />;
  }

  return maxFails ? (
    <Redirect to={'/'} />
  ) : (
    <div className="wkp-spreadsheet-container">
      {commandFailed && <GeneralErrorMessage dismissible={false} visible={commandFailed} />}

      <BTAlert
        appear
        dismissible
        fixed
        btStyle="success"
        visible={!!successAlertMessage}
        onDismiss={handleSuccessAlertClose}
      >
        {successAlertMessage && <RenderAlert alertData={successAlertMessage} alertType={successAlertMessage?.type} />}
      </BTAlert>

      <BTAlert appear fixed btStyle="info" visible={dataLinkStatus.active}>
        Linking mode is on for workpaper {dataLinkStatus.targetWorkpaperName}
      </BTAlert>

      {isGlobalTemplate && (
        <BTAlert appear dismissible fixed btStyle="warning" visible={true}>
          This workpaper has been marked as a global template and is locked as a read-only. To unlock this workpaper,
          open the <strong>workpaper settings panel</strong> and <strong>Unmark as Global Template</strong>.
        </BTAlert>
      )}

      <BTAlert appear dismissible fixed btStyle="info" visible={showProgressAlert} onDismiss={() => {}}>
        {'Copying in progress...'}
      </BTAlert>

      <BTAlert appear dismissible fixed btStyle="danger" visible={!!showCopyAlert} onDismiss={() => {}}>
        {showCopyAlert?.message}
      </BTAlert>

      {onReadOnlyMode && <ReadOnlyVersionBar displayName={readOnlyModeDisplayName} workpaperId={workpaperId} />}

      {readerMode ||
        (isLocked && !onReadOnlyMode && (
          <WorkpaperLockedBar
            userName={userLockFullName}
            workpaperStatus={workpaperStatus}
            onNeedsReload={onNeedsReload}
          />
        ))}

      <SpreadView
        key={`${workpaperId}${versionId}`}
        addToHistory={addToHistory}
        cellReviewFunctionManager={cellReviewFunctionManager}
        handleFileMenuAction={handleFileMenuAction}
        isLoading={isLoading}
        readOnly={onReadOnlyMode || isLocked || isGlobalTemplate || readerMode}
        setCommandsVisibleContext={setCommandsVisibleContext}
        setHasPendingChanges={setHasPendingChanges}
        versionId={versionId}
        workpaperId={workpaperId}
        onWorkbookInit={handleWorkbookInit}
        onWorkpaperCopySheetModalOpen={handleWorkpaperCopySheetModalOpen}
        onWorkpaperSendToSFTPModalOpen={handleWorkpaperSendToSFTPModalOpen}
        onWorkpaperSendToTPModalOpen={handleWorkpaperSendToTPModalOpen}
      />
      <SideBar
        inHistoryViewMode={onReadOnlyMode}
        isLocked={isLocked}
        setReadOnlyModeDisplayName={setReadOnlyModeDisplayName}
        workpaperId={workpaperId}
      />

      <TaxLawSummary />

      {showWorkpaperCopySheetModal && (
        <WorkpaperCopySheetModal
          handleCopySheetSjs={handleCopySheetSjs}
          sheetToCopy={spreadRef.current.getActiveSheet().name()}
          show={showWorkpaperCopySheetModal}
          sourceWorkpaperId={workpaperId}
          onClose={handleWorkpaperCopySheetModalClose}
        />
      )}

      {showWorkpaperSendToTPModal && (
        <WorkpaperSendToTPModal
          closeSuccessModal={handleWorkpaperSendToTPSuccessModalClose}
          show={handleWorkpaperSendToTPModalOpen}
          showSuccessModal={handleWorkpaperSendToTPSuccessModalOpen}
          sourceTaxPeriod={taxPeriod}
          sourceWorkpaperId={workpaperId}
          sourceWorkpaperName={workbookName}
          onClose={handleWorkpaperSendToTPModalClose}
        />
      )}
      {showWorkpaperSendToTSuccessModal && (
        <WorkpaperSendToTPSuccessModal
          show={handleWorkpaperSendToTPSuccessModalOpen}
          onClose={handleWorkpaperSendToTPSuccessModalClose}
        />
      )}
      {showWorkpaperSendToSFTPModal && (
        <SftpModal
          generalError={generalError}
          resourceId={workpaperId}
          resourceType={ResourceType.Spreadsheet}
          setGeneralError={setGeneralError}
          show={handleWorkpaperSendToSFTPModalOpen}
          workpaperInfo={sourceWorkpaperInfo}
          onClose={handleWorkpaperSendToSFTPModalClose}
          onOpen={onOpenSftpModal}
        />
      )}
      {showWorkpaperSendToSFTPSuccessModal && (
        <BTAlert
          appear
          dismissible
          fixed
          btStyle="success"
          visible={true}
          onDismiss={handleWorkpaperSendToSFTPSuccessModalClose}
        >
          The sheet(s) have been sent to SFTP folder
          {sftpFolderPath !== '' && (
            <>
              {' '}
              <strong
                style={{
                  display: 'inline-block',
                  maxWidth: '200px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  direction: 'rtl',
                  verticalAlign: 'bottom',
                }}
                title={sftpFolderPath.replace(/^\//, '')}
              >
                {sftpFolderPath.replace(/^\//, ' ')}
              </strong>
            </>
          )}
          .
        </BTAlert>
      )}
      {showWorkpaperSyncCommandsModal && (
        <WorkpaperSyncCommandsModal show={showWorkpaperSyncCommandsModal} workpaperId={workpaperId} />
      )}
    </div>
  );
}
