import { useContext, useEffect } from 'react';
import EditorContext from './EditorContext';
import { useMetadata } from './Spreadsheet/SideBar/SettingsPanel/useMetadata';
import GC from '../../../SpreadSheets';
import { useEditorToggle } from './useEditorToggle';
import { setValueOnServer } from './useWorkpaper/processCommand';
import { createWorkpaperDataReferences } from './DataReference/apis';
import { DATA_LINK } from '../../_shared/DataReference/ReferenceType';
import { v4 as uuidv4 } from 'uuid';
import { applyTimestamp } from '../../../sjs-cmd-process';

export function useDataLink({ workpaperId }) {
  const targetWorkbookStorage = 'linking.target';
  const sourceWorkbookStorage = 'linking.source';
  const endDataLinkCommandName = 'endDataLink';
  const sourceSelectionColor = '#AB63DB';
  const defaultSelectionColor = '#0073FF';

  const { spreadRef, setDataLinkStatus, enqueueCommands } = useContext(EditorContext);
  const { getMetadata } = useMetadata({ workpaperId });
  const { disableEditor, enableEditor } = useEditorToggle();

  async function handleFinishDataLink() {
    const sheet = spreadRef.current.getActiveSheet();
    const [{ row, col: column }] = sheet.getSelections();
    const sheetName = sheet.name();
    const value = sheet.getValue(row, column);
    const { taxPeriod, name } = await getMetadata();

    localStorage.setItem(
      sourceWorkbookStorage,
      JSON.stringify({
        taxPeriod,
        name,
        sheetName,
        row,
        column,
        value,
        isFinal: true,
      })
    );
  }

  function sourceWorkbookHandler(linkStorageState) {
    if (linkStorageState && linkStorageState.workpaperId !== workpaperId) {
      setDataLinkStatus({ active: true, targetWorkpaperName: linkStorageState.workpaperName });
      disableEditor();
      spreadRef.current.commandManager().setShortcutKey('commitInputNavigationDown', false);
      spreadRef.current.commandManager().setShortcutKey(endDataLinkCommandName, GC.Spread.Commands.Key.enter);
      spreadRef.current.getActiveSheet().options.selectionBorderColor = sourceSelectionColor;
    } else {
      setDataLinkStatus({ active: false, targetWorkpaperName: null });
      enableEditor();
      spreadRef.current.commandManager().setShortcutKey('commitInputNavigationDown', GC.Spread.Commands.Key.enter);
      spreadRef.current.commandManager().setShortcutKey(endDataLinkCommandName, false);
      spreadRef.current.getActiveSheet().options.selectionBorderColor = defaultSelectionColor;
    }
  }

  function toA1Notation(row, column, isRelative = false) {
    const charCodeA = 65;
    const alphabetLength = 26;
    let columnLabel = '';

    for (let c = ++column; c > 0; c = Math.floor((c - 1) / 26)) {
      const transposition = (c - 1) % alphabetLength;
      columnLabel = String.fromCharCode(charCodeA + transposition) + columnLabel;
    }

    return isRelative ? `${columnLabel}${++row}` : `$${columnLabel}$${++row}`;
  }

  function targetWorkbookHandler(linkStorageState, event) {
    if (linkStorageState.workpaperId === workpaperId) {
      const {
        taxPeriod,
        name,
        sheetName,
        row: sourceRow,
        column: sourceColumn,
        value,
        isFinal,
      } = JSON.parse(event.newValue);
      const { row: targetRow, column: targetColumn, previousValue } = linkStorageState;

      const effectiveTaxPeriod = taxPeriod ? `${taxPeriod}/` : '';

      const a1Coordinate = toA1Notation(sourceRow, sourceColumn);
      const formula = `='${effectiveTaxPeriod}[${name}]${sheetName}'!${a1Coordinate}`;

      const ss = spreadRef.current;
      const sheet = ss.getActiveSheet();
      sheet.setFormula(targetRow, targetColumn, formula);

      if (!isFinal) return;

      const partialWorkbook = {
        [sheetName]: {
          [sourceRow]: {
            [sourceColumn]: value,
          },
        },
      };

      ss.updateExternalReference(name, partialWorkbook, effectiveTaxPeriod, true);
      const command = applyTimestamp(setValueOnServer(sheet, targetRow, targetColumn, previousValue, formula));
      command.partialWorkbook = partialWorkbook;
      command.workpaperName = name;
      command.taxPeriod = effectiveTaxPeriod;
      enqueueCommands([{ commandText: JSON.stringify(command) }]);
      createWorkpaperDataReferences(workpaperId, [
        {
          row: targetRow,
          column: targetColumn,
          referenceId: uuidv4(),
          sheetName: sheet.name(),
          referenceType: DATA_LINK,
          oldValue: '',
          newValue: value,
          parameters: {
            taxPeriod,
            workpaperName: name,
            sheetName,
            a1Coordinate,
          },
        },
      ]);
      sheet.endEdit(true);
    }
  }

  function setupStorageListener() {
    if (!window) return;
    function listener(event) {
      if (event.storageArea === localStorage) {
        const linking = JSON.parse(localStorage.getItem(targetWorkbookStorage));
        switch (event.key) {
          case targetWorkbookStorage: {
            sourceWorkbookHandler(linking);
            break;
          }
          case sourceWorkbookStorage: {
            targetWorkbookHandler(linking, event);
            break;
          }
          default:
            break;
        }
      }
    }
    window.addEventListener('storage', listener);
    return listener;
  }

  function sourceWorkbookSpreadListeners() {
    async function setDataLinkSource(row, column, sheetName, value) {
      const { taxPeriod, name } = await getMetadata();
      localStorage.setItem(
        sourceWorkbookStorage,
        JSON.stringify({
          taxPeriod,
          name,
          sheetName,
          row,
          column,
          value,
        })
      );
    }
    const ss = spreadRef.current;

    if (!ss) return;

    ss.bind(GC.Spread.Sheets.Events.SelectionChanged, function (e, { sheet, newSelections, sheetName }) {
      const linking = JSON.parse(localStorage.getItem(targetWorkbookStorage));
      if (linking && workpaperId !== linking.workpaperId) {
        const [{ row, col: column }] = newSelections;
        const value = sheet.getValue(row, column);
        setDataLinkSource(row, column, sheetName, value);
      }
    });

    ss.bind(GC.Spread.Sheets.Events.ActiveSheetChanged, function (e, { newSheet, oldSheet }) {
      const linking = JSON.parse(localStorage.getItem(targetWorkbookStorage));
      if (linking && workpaperId !== linking.workpaperId) {
        oldSheet.options.selectionBorderColor = defaultSelectionColor;
        const [{ row, col }] = newSheet.getSelections();
        const value = newSheet.getValue(row, col);
        const cellData = JSON.parse(localStorage.getItem(sourceWorkbookStorage));
        cellData.sheetName = newSheet.name();
        cellData.row = row;
        cellData.column = col;
        cellData.value = value;
        localStorage.setItem(sourceWorkbookStorage, JSON.stringify(cellData));
      }
    });
  }

  function targetWorkbookSpreadListener() {
    async function setDataLinkTarget(row, column, previousValue) {
      const { name } = await getMetadata();
      localStorage.setItem(
        targetWorkbookStorage,
        JSON.stringify({
          workpaperId,
          workpaperName: name,
          row,
          column,
          previousValue,
        })
      );
    }
    const ss = spreadRef.current;

    if (!ss) return;

    ss.bind(GC.Spread.Sheets.Events.EditChange, function (e, { sheet, row, col, editingText }) {
      if (editingText === '=') {
        setDataLinkTarget(row, col, sheet.getValue(row, col));
      } else {
        localStorage.removeItem(targetWorkbookStorage);
      }
    });

    ss.bind(GC.Spread.Sheets.Events.EditEnded, function (e, a) {
      localStorage.removeItem(targetWorkbookStorage);
    });
  }

  function setupSpreadListeners() {
    targetWorkbookSpreadListener();
    sourceWorkbookSpreadListeners();

    spreadRef.current.commandManager().register(endDataLinkCommandName, { execute: handleFinishDataLink });
  }

  useEffect(() => {
    const listener = setupStorageListener();
    return () => window.removeEventListener('storage', listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { setupSpreadListeners };
}
