import React, { useState, useEffect } from 'react';
import { BTModal, BTButton, BTCheckbox } from '@btas/jasper';
import './styles.scss';
import { dataFlowOutputExport } from './apis';
import { sendToFA } from './sendToFA';
import { dataFlowServiceHostName } from '../../../../../configs/params.js';
import CustomLogger from '../../../../_shared/Logger/CustomLogger.js';
import { useTrackInteractiveClick } from '../../../../_shared/EventTrackingContext.jsx';
import { itemTypes } from '../../../../_shared/EventTrackingContext/utils.js';

function getOutputName(location) {
  const parts = location.split('/');
  return parts[4].split('.')[0];
}

export function FAExportModal({
  currentRun,
  dataFlowName,
  id,
  isShow,
  taxPeriod,
  updateOutputOnExport,
  handleCancel,
  handleClose,
  setFaNotificationOpened,
  setSendToFixedAssetsResult,
}) {
  const [selectedIds, setSelectedIds] = useState([]);
  const [sendToFAButton, setSendToFAButton] = useState(true);

  const trackEvent = useTrackInteractiveClick();
  useEffect(() => {
    // Enable button if at least one checkbox is checked
    setSendToFAButton(selectedIds.length === 0);
  }, [selectedIds]);

  const handleExport = async () => {
    const exportedOutputIds = selectedIds.map(id => {
      return id;
    });
    handleClose();
    try {
      const outputsExported = await dataFlowOutputExport(currentRun.id, {
        exportedOutputIds: exportedOutputIds,
        destination: 'FA',
      });

      const createResult = await sendToFA(
        currentRun,
        outputsExported,
        id,
        dataFlowName,
        taxPeriod,
        dataFlowServiceHostName
      );
      CustomLogger.pushLog(CustomLogger.operations.SEND_TO_FA, {
        currentRun: JSON.stringify(currentRun),
        outputsExported: JSON.stringify(outputsExported),
        dataFlowName: JSON.stringify(dataFlowName),
        taxPeriod: JSON.stringify(taxPeriod),
        message: `send to FA on FAExportModal with dataflow id :${id} and dataflowrun id :${currentRun.id}`,
      });

      setSendToFixedAssetsResult(createResult);
      setFaNotificationOpened(true);
      trackEvent('Send to Fixed Assets Link Click - Select Outputs modal', itemTypes.BUTTON);
    } catch (error) {
      CustomLogger.pushLog(CustomLogger.operations.SEND_TO_FA, {
        error: JSON.stringify(error),
        message: `catch error when handle export with dataflow id :${id} and dataflowrun id :${currentRun.id}`,
      });

      setSendToFixedAssetsResult({ ok: false });
      setFaNotificationOpened(true);
      handleClose();
    }
  };

  const handleCheckboxChange = event => {
    const selectedId = event.target.id;
    const isSelected = event.target.checked;
    setSelectedIds(prevSelectedIds => {
      if (isSelected) {
        return [...prevSelectedIds, selectedId];
      } else {
        return prevSelectedIds.filter(id => id !== selectedId);
      }
    });
  };

  return (
    <>
      <BTModal
        show={isShow}
        size="modal-sm"
        title="Select Outputs to send to Fixed Assets"
        onCloseClick={() => handleCancel()}
      >
        <BTModal.Body>
          <div className="fa-modal-container">
            <p>
              No output blocks have been configured to send data to Fixed Assets. Select at least one output to include.
            </p>
            <div className="df-output-checkboxes">
              {Object.entries(currentRun.outputDataLocations).map(([outputId, outputData]) => (
                <div key={outputId}>
                  <BTCheckbox
                    className="checkbox"
                    id={outputId}
                    label={getOutputName(outputData.location)}
                    onChange={handleCheckboxChange}
                  />
                </div>
              ))}
            </div>
          </div>
        </BTModal.Body>
        <BTModal.Footer>
          <>
            <BTButton btStyle="secondary" onClick={handleCancel}>
              Cancel
            </BTButton>

            <BTButton btStyle="primary" disabled={sendToFAButton} onClick={handleExport}>
              SEND TO FIXED ASSETS
            </BTButton>
          </>
        </BTModal.Footer>
      </BTModal>
    </>
  );
}
