import { analyticApiKey, analyticsEnvironment, skipEventsTracking } from '../../../configs/params.js';
import * as ampliIndex from './Amplitude/ampli/index.js';
import { ampli } from './Amplitude/ampli/index.js';
import { autocapturePlugin } from '@amplitude/plugin-autocapture-browser';
import { automatedUsers } from './blackListing.js';

export default class Tracker {
  constructor(staticData) {
    this.env = analyticsEnvironment;
    this.staticData = staticData;
    this._setUpAmplitude();
  }

  _additionalPageProperties() {
    let pageTitle = 'INITIAL_RENDER';
    const titleEl = document.querySelector('head title');

    if (titleEl && titleEl.innerHTML !== '') {
      pageTitle = titleEl.innerHTML;
    }

    return {
      appVersion: `Tracking Plan Version: ${ampliIndex.DefaultConfiguration.plan.version}`,
      domain: 'bloombergtax.com' || window.location.host,
      pageTitle: pageTitle,
      previousPage: document.referrer,
      url: window.location.href,
    };
  }

  track(eventName, payload) {
    this._groupIdentify();

    const propertiesFromSharedData = this._propertiesFromSharedData();
    const additionalPageProperties = this._additionalPageProperties();

    let eventPayload;

    eventPayload = {
      ...additionalPageProperties,
      ...propertiesFromSharedData,
      ...payload,
    };

    //send event to amplitude
    ampli[eventName](eventPayload);
  }

  isValid(eventName) {
    return ampli[eventName] ? true : false;
  }
  _getPagesInfo() {
    const url = window.location.href;
    let pageType = '';
    let product = '';

    const dataConnectRegex = /\/data-flows(\/.*)?$/;
    const workpapersRegex = /\/workpapers(\/.*)?$/;
    const spreadsheetRegex = /\/editor\/.+$/;
    const recycleBinRegex = /\/workpapers\/recycle-bin$/;

    if (dataConnectRegex.test(url)) {
      product = 'Data Connect';
      if (dataConnectRegex.test(url) && url.endsWith('/data-flows')) {
        pageType = 'Data Connect Landing Page';
      } else {
        pageType = 'Data Connect';
      }
    } else if (workpapersRegex.test(url)) {
      product = 'Workpapers';
      if (workpapersRegex.test(url) && url.endsWith('/workpapers')) {
        pageType = 'Workpapers Landing Page';
      } else if (recycleBinRegex.test(url)) {
        pageType = 'Recycle Bin';
      } else {
        pageType = 'Workpapers';
      }
    } else if (spreadsheetRegex.test(url)) {
      product = 'Spreadsheet';
      pageType = 'Spreadsheet Editor';
    }

    return { pageType, product };
  }

  _propertiesFromSharedData() {
    const { pageType, product } = this._getPagesInfo();
    return {
      appName: 'Workpapers',
      brand: 'BTAX',
      channel: null,
      isLoggedIn: true, // we only send data to amplitude if user is signed in into the application
      httpStatusCode: 200,
      pageType,
      platform: 'BTAX Software',
      product,
    };
  }

  _identifyUser() {
    ampli.identify(this.staticData.userId, {
      userAgent: navigator.userAgent,
      customerNumber: this.staticData.companyId,
    });

    this._groupIdentify();

    ampli.setGroup('ecdsUnitNumber', this.staticData.companyId);
  }

  _groupIdentify() {
    ampli.groupIdentify('ecdsUnitNumber', this.staticData.companyId, {
      customerNumber: this.staticData.companyId,
    });
  }

  _setUpAmplitude() {
    if (!this.staticData) return;
    if (ampli.isLoaded) return;
    if (!analyticApiKey || !analyticsEnvironment) return; // We don't need to track on local environment. If needed then we need to comment this line and add the proper values in .env file
    if (skipEventsTracking) return; // flag to skip events tracking

    if (automatedUsers.includes(this.staticData.userId)) return; // automation users won't do tracking
    const ENDPOINT_WITH_CNAME = 'https://a.blaw.com/2/httpapi';

    ampli.load({
      environment: this.env,
      client: {
        configuration: {
          serverUrl: window.amplitude_server || ENDPOINT_WITH_CNAME,
          includeUtm: true,
          includeReferrer: true,
          defaultTracking: false,
          disableCookies: true,
        },
        apiKey: analyticApiKey,
      },
    });

    //activate plugging to do event tagging in Amplitude dashboard
    ampli.client.add(autocapturePlugin());

    this._identifyUser();
  }
}
