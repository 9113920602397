import { useState, useCallback, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import * as apis from './apis';
import { transformToConfiguration } from '../../useSaveDataFlow/transformToConfiguration';
import { DataFlowEditorContext } from '../../DataFlowEditorContext';
import { isFeatureFlagEnabled } from '../../../../../utils/featureFlags';
import { WKP_INPUT_FILE_IMPORT } from '../../../../../constants/featureFlags';
import { InputElementType } from '../../elementType/types/InputElementType';

export default function useCopyDataFlow() {
  const { dataFlowActions } = useContext(DataFlowEditorContext);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const copyDataFlow = useCallback(
    async (dataFlow, name, taxPeriod, includeSourceFiles) => {
      setError(null);
      setLoading(true);
      try {
        if (!includeSourceFiles && isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT)) {
          for (const key in dataFlow.elements) {
            if (dataFlow.elements[key]?.elementType?.type === InputElementType.TYPE) {
              if (dataFlow.elements[key]?.elementData?.uploadedFile)
                delete dataFlow.elements[key]?.elementData?.uploadedFile;
              if (dataFlow.elements[key]?.elementData?.import) delete dataFlow.elements[key]?.elementData?.import;
              if (dataFlow.elements[key]?.elementData?.hasSourceFileUploaded)
                delete dataFlow.elements[key]?.elementData?.hasSourceFileUploaded;
            }
          }
        }

        const response = await apis.copyDataFlow(
          transformToConfiguration(dataFlow.elements, dataFlow.links),
          name,
          taxPeriod,
          includeSourceFiles,
          dataFlow.id
        );
        if (response.error) {
          const validationDetails = response.error?.details.reduce(
            (acc, err) => ({ ...acc, [err.target]: err.message }),
            {}
          );
          setError(validationDetails || response.error.message);
        } else {
          dataFlowActions.setSaveStateDirty(false);
          setTimeout(() => history.push(`/data-flows/${response.id}/editor`));
        }
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    },
    [history, dataFlowActions]
  );

  const reset = useCallback(() => setError(false), [setError]);

  const state = useMemo(() => ({ error, isLoading }), [error, isLoading]);

  const actions = useMemo(
    () => ({
      copyDataFlow,
      reset,
    }),
    [copyDataFlow, reset]
  );

  return [state, actions];
}
