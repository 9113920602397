import React, { useState, useRef } from 'react';
import { SJS_API } from '../../../constants/featureFlags';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import useCommandsQueue from './EditorContext/useCommandsQueue';
import useSjsCommandsQueue from './EditorContext/useSjsCommandsQueue';
import useStatusBar from './EditorContext/useStatusBar';
import useEditorReducer from './useEditorReducer';
import useDataReferenceManager from './DataReference/useDataReferenceManager';

const EditorContext = React.createContext();
const { Provider, Consumer } = EditorContext;

function EditorContextProvider({ children, value }) {
  const spreadRef = useRef(value?.spreadRef);
  const [workbookName, setWorkbookName] = useState(document.title);
  const [taxPeriod, setTaxPeriod] = useState(null);
  const [isGlobalTemplate, setGlobalTemplate] = useState(false);
  const [workpaperStatus, setWorkpaperStatus] = useState(document.title);
  const [dataLinkStatus, setDataLinkStatus] = useState({ active: false, targetWorkpaperName: null });
  const dataReferences = useRef([]);
  const dataReferenceValues = useRef([]);
  const stateTaxJurisdictions = useRef([]);
  const isDragFillAction = useRef({ isActive: false, direction: undefined });
  const isCopyPasteAction = useRef(false);
  const trackDataReferencesAction = useRef(null);
  const loadDataReferencesAction = useRef(null);
  const cellReviewFunctionManager = useRef({});
  const cellReviewManagerInitialized = useRef(false);
  const dataReferenceHistoryTracker = useRef({});
  const cellChangedData = useRef(null);
  const syncDatareferences = { trackDataReferencesAction, loadDataReferencesAction };
  const sjsCommandProcessingEnabled = isFeatureFlagEnabled(SJS_API);
  const dataReferenceWorkpaperId = useRef('');
  const dataReferenceWorkpaperVersionId = useRef('');
  const lastCommandAppliedTime = useRef(null);
  const commandsQueue = useCommandsQueue({ spreadRef, syncDatareferences });
  const sjsCommandsQueue = useSjsCommandsQueue({
    spreadRef,
    dataReferenceWorkpaperId,
    syncDatareferences,
    lastCommandAppliedTime,
  });

  const activeCommandsQueue = sjsCommandProcessingEnabled ? sjsCommandsQueue : commandsQueue;
  const tooltipManagerRef = useRef(value?.tooltipManager);
  const hasPendingChangesDefaultValue = value?.hasPendingChanges !== undefined ? value.hasPendingChanges : false;
  const [hasPendingChanges, setHasPendingChanges] = useState(hasPendingChangesDefaultValue);

  const { isCommandsSaving, enqueueCommands, allCommandsProcessedAsync, commandFailed, maxFails, setWorkpaperId } =
    activeCommandsQueue;

  const isLoadingDefaultValue = value?.isLoading !== undefined ? value.isLoading : true;
  const isLoadingInBackgroundDefaultValue =
    value?.isLoadingInBackground !== undefined ? value.isLoadingInBackground : true;
  const isLockedDefaultValue = value?.isLocked !== undefined ? value.isLocked : false;

  const [isLoading, setIsLoading] = useState(isLoadingDefaultValue);
  const [isLoadingInBackground, setIsLoadingInBackground] = useState(isLoadingInBackgroundDefaultValue);
  const [isLocked, setIsLocked] = useState(isLockedDefaultValue);
  const [successAlertMessage, setSuccessAlertMessage] = useState();
  const [isSignOffPermission, setIsSignOffPermission] = useState(false);
  const [showWorkpaperSyncCommandsModal, setShowWorkpaperSyncCommandsModal] = useState(false);
  const {
    enqueueDataReference,
    enqueueManyDataReferences,
    processCellReviewQueue,
    loadWorkbookDataReferences,
    trackDataReferenceCellPositions,
    setReferenceCellTags,
    trackPastedReference,
    clearReferenceCell,
    renderCustomFormulaValues,
    getCellReferenceTag,
    enqueueDataReferenceReCalc,
    referenceExistInWorksheet,
    referenceExistInTargetCell,
    referenceIsEnqueuedForRecalc,
    isFormulaMatch,
    processDataReferencesDeleteQueue,
    isProcessing,
    dataReferenceDeleteQueue,
    processCellReviewUndoQueue,
    isRecalcPostitions,
    dataReferenceQueue,
  } = useDataReferenceManager({
    dataReferences,
    dataReferenceValues,
    isDragFillAction,
    isCopyPasteAction,
    enqueueCommands,
    tooltipManagerRef,
    spreadRef,
    dataReferenceHistoryTracker,
    dataReferenceWorkpaperId,
    dataReferenceWorkpaperVersionId,
    cellReviewManagerInitialized,
  });
  cellReviewFunctionManager.current = {
    enqueueManyDataReferences,
    processCellReviewQueue,
    processDataReferencesDeleteQueue,
    dataReferenceDeleteQueue,
    dataReferences,
    processCellReviewUndoQueue,
    isRecalcPostitions,
    loadWorkbookDataReferences,
  };
  const { editorState, actions } = useEditorReducer();
  const reducerValue = { editorState, actions };

  const commandMapRef = useRef();
  const { addStatusBar, getStatusBarItem, statusBarAddedRef } = useStatusBar({
    isCommandsSaving: isCommandsSaving ?? editorState.isCommandsSaving,
    isDataReferenceSaving: isProcessing,
    hasPendingChanges,
  });

  const setCommandMap = commandMap => (commandMapRef.current = commandMap);

  const setCommandsVisibleContext = commands => {
    if (commandMapRef.current) {
      Object.entries(commands).forEach(([key, value]) => {
        const command = commandMapRef.current[key];

        if (command) {
          command.visibleContext = value;
        }
      });
    }
  };

  const setCommandsEnableContext = commands => {
    if (commandMapRef.current) {
      Object.entries(commands).forEach(([key, value]) => {
        const command = commandMapRef.current[key];

        if (command) {
          command.enableContext = value;
        }
      });
    }
  };

  return (
    <Provider
      value={{
        spreadRef,
        workbookName,
        isLoading,
        isLoadingInBackground,
        isLocked,
        isCommandsSaving,
        isSignOffPermission,
        hasPendingChanges,
        successAlertMessage,
        workpaperStatus,
        isGlobalTemplate,
        dataLinkStatus,
        lastCommandAppliedTime,
        setWorkbookName,
        setIsLoading,
        setIsLoadingInBackground,
        setIsLocked,
        setCommandMap,
        setIsSignOffPermission,
        setCommandsVisibleContext,
        setCommandsEnableContext,
        setWorkpaperStatus,
        setGlobalTemplate,
        setDataLinkStatus,
        addStatusBar,
        getStatusBarItem,
        statusBarAddedRef,
        setHasPendingChanges,
        enqueueCommands,
        allCommandsProcessedAsync,
        enqueueDataReference,
        enqueueManyDataReferences,
        enqueueDataReferenceReCalc,
        dataReferenceQueue,
        dataReferences,
        setWorkpaperId,
        setSuccessAlertMessage,
        reducerValue,
        commandFailed,
        maxFails,
        loadWorkbookDataReferences,
        trackDataReferenceCellPositions,
        setReferenceCellTags,
        trackPastedReference,
        stateTaxJurisdictions,
        clearReferenceCell,
        renderCustomFormulaValues,
        getCellReferenceTag,
        isDragFillAction,
        isCopyPasteAction,
        referenceExistInWorksheet,
        referenceExistInTargetCell,
        referenceIsEnqueuedForRecalc,
        isFormulaMatch,
        syncDatareferences,
        tooltipManagerRef,
        cellReviewFunctionManager,
        dataReferenceHistoryTracker,
        dataReferenceWorkpaperId,
        cellChangedData,
        dataReferenceWorkpaperVersionId,
        taxPeriod,
        setTaxPeriod,
        showWorkpaperSyncCommandsModal,
        setShowWorkpaperSyncCommandsModal,
      }}
    >
      {children}
    </Provider>
  );
}

export default EditorContext;
export { EditorContextProvider, Consumer as EditorContextConsumer };
